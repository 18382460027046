import React, { useCallback, useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import type { ExtNode } from 'relatives-tree/lib/types';
import css from './FamilyNode.module.css';
import male from './male-avatar.png';
import female from './female-avatar.png';
import NewMemberForm from '../newMemberForm/newMemberForm';
import { useParams } from 'react-router-dom';
import EditFamilyNode from './EditFamilyNode';
import { EditFamilyNodeContext } from '../../context/EditFamilyNodeContext';
import NavBar from '../Welcome/NavBar';
import API from '../../api/api';

interface CustomNode extends ExtNode {
  firstName: string;
  lastName: string;
  imageUrl: string;
  dateOfBirth: string;
  age: string;
}

interface FamilyNodeProps {
  node: CustomNode;
  isRoot: boolean;
  isHover?: boolean;
  onClick: (id: string) => void;
  onSubClick: (id: string) => void;
  style?: React.CSSProperties;
  onMemberDelete?: () => void;
  onFamilyTreeUpdate: () => void;
}



export const FamilyNode = React.memo(
  function FamilyNode({ node, isRoot, isHover, onClick, onSubClick, style, onMemberDelete }: FamilyNodeProps) {
    const clickHandler = useCallback(() => onClick(node.id), [node.id, onClick]);
    const clickSubHandler = useCallback(() => onSubClick(node.id), [node.id, onSubClick]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isImageOpen, setImageOpen] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [option, setOption] = useState("");
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
    const [currentNodeId, setCurentNodeid] = useState<string>("");
    const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
    const { board_id } = useParams<{ board_id: string }>();
    const validatedBoardId = board_id ?? '';
    const context = useContext(EditFamilyNodeContext);
    const [menuVisible, setMenuVisible] = useState(false);
    const [isSubTreeHovered, setIsSubTreeHovered] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); 


    const handleMenuClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setMenuVisible((prevState) => !prevState);
    };


    if (!context) {
      throw new Error('EditFamilyNodeContext must be used within an EditFamilyNodeProvider');
    }

    const { openEditor, setFamilyTreeId } = context;
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };

    const toggleImage = () => {
      setImageOpen(!isImageOpen);
    }
    const toggleEdit = () => {
      setIsEditing(!isEditing);
    }

    const closeMenu = () => {
      setMenuOpen(false);
    };

    const openForm = () => {
      setFormOpen(true);
      closeMenu();
    };

    const closeForm = () => {
      setFormOpen(false);
    }

    const HandleAddSpouse = () => {
      setOption("spouse");
      // if (node.children.length === 0) {
      openForm();
      // }
    }

    const handleAddChild = () => {
      setOption("child");
      openForm();
    }

    const handleAddFather = () => {
      setOption("parent");
      openForm();
    }

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setUploadedImageFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleSubmitImage = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!uploadedImageFile) {
        console.error("No image file uploaded");
        return;
      }

      const formData = new FormData();
      formData.append('image', uploadedImageFile);
      setIsLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("token") || '""');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/family_tree/${board_id}/updatememberImage/${currentNodeId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data, "Image uploaded successfully");
        toggleImage();
        window.location.reload();
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsLoading(false)
      }
    };
    const handleClose = () => {
      toggleEdit();
      setSelectedMemberId(null);
    };


    const handleSave = () => {
      toggleEdit();
    };

    const handleEdit = (e: any) => {
      e.stopPropagation();
      openEditor(node.id);
      setFamilyTreeId(validatedBoardId);
      console.log("current NodeId", node);
      toggleEdit();
    }

    const handleDelete = async (e: React.MouseEvent, nodeId: string) => {
      e.stopPropagation();
      setIsDeleting(true);

      try {
        const token = JSON.parse(localStorage.getItem('token') || '""');
        const response = await API.delete(`/family_tree/${validatedBoardId}/member/${nodeId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          onMemberDelete?.();
        } else {
          console.log('error in delete', response.data);
          alert(response.data.message || 'Failed to delete family member');
        }
      } catch (error: any) {
        console.error('Error deleting member:', error);
        alert(error.response.data.message || 'Failed to delete family member. Please try again.');
      } finally {
        setIsDeleting(false);
        setMenuOpen(false);
      }
    };

    const confirmDelete = (e: React.MouseEvent, nodeId: string) => {
      e.stopPropagation();
      setIsDeleteDialogOpen(true); // Open the delete confirmation dialog
    };

    const cancelDelete = () => {
      setIsDeleteDialogOpen(false); // Close the delete confirmation dialog
    };

    const confirmDeleteAction = (e: React.MouseEvent, nodeId: string) => {
      handleDelete(e, nodeId); // Confirm the deletion
      setIsDeleteDialogOpen(false); // Close the confirmation dialog
    };

    const handleAddSibling = () => {
      setOption("sibling");
      openForm();
    }

    return (
      <section>
        {isDeleting && <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>}
        <div>
          {isFormOpen && <NewMemberForm onClose={closeForm} node={node} option={option} onMemberAdded={onMemberDelete} />}
          {isMenuOpen && <div className={css.overlay} onClick={closeMenu}></div>}
          {isMenuOpen && (
            <div className={css.menu}>
              <span className={css.closeButton} onClick={closeMenu}>✖</span>
              <ul>
                <li key="add-father" onClick={handleAddFather}>Add Parent</li>
                <li key="add-spouse" onClick={HandleAddSpouse}>Add Spouse</li>
                <li key="add-son" onClick={handleAddChild}>Add Child</li>
                <li key="add-son" onClick={handleAddSibling}>Add Sibling</li>
              </ul>
            </div>
          )}

{isDeleteDialogOpen && (
  <div className={css.deleteDialog}>
    <div className={css.deleteDialogContent}>
      <h4>Are you sure you want to delete this member?</h4>
      <button onClick={(e) => confirmDeleteAction(e, node.id)} className={css.deleteButton}>
        Yes, Delete
      </button>
      <button onClick={cancelDelete} className={css.cancelButton}>
        Cancel
      </button>
    </div>
  </div>
)}


          <div className={css.root} style={style}>
            <div
              className={classNames(
                css.inner,
                css[node.gender],
                isRoot && css.isRoot,
                isHover && css.isHover,
              )}
              onClick={clickHandler}
            >
              <div className={css.id}>
                <div className={`${css.node} ${node.gender === "male" ? css.male : css.female}`}>

                  <div className={css.controll_icons}>
                    <i
                      className={`fas fa-ellipsis-h ${css.editIcon}`}
                      onClick={handleMenuClick} // Open or close menu
                    ></i>
                  </div>

                  {menuVisible && (
  <div className={css.refmenu}>
    {/* Close Button */}
    <span
      className={css.closeMenuButton}
      onClick={() => setMenuVisible(false)}
    >
      <i className="fas fa-times" style={{ fontSize: "14px" }}></i> {/* Using Font Awesome close icon */}
    </span>
    <ul>
      <li onClick={handleEdit}>
        <i className="fas fa-edit" style={{ marginRight: "8px" }}></i>
        Edit Member
      </li>
      <li key="delete" onClick={(e) => confirmDelete(e, node.id)}>
                <i className="fas fa-trash-alt" style={{ marginRight: '8px' }}></i>
                Delete Member
              </li>
      <li>
        <i className="fas fa-user" style={{ marginRight: "8px" }}></i>
        View Profile
      </li>
    </ul>
  </div>
)}



                  <div className={css.profileContainer}>
                    <img src={node?.imageUrl || (node.gender === "male" ? male : female)} alt={`Profile`} className={css.profilePic} />
                  </div>

                  <div>
                    <i className={`fas fa-camera ${css.cameraIcon}`} onClick={(e) => {
                      e.stopPropagation();
                      setCurentNodeid(node.id);
                      toggleImage();
                    }}></i>
                  </div>

                  <div className={css.details}>
                    <span className={css.name}>{node.firstName + " " + node.lastName}</span>
                    <span className={css.birthYear}>{node.dateOfBirth}</span>
                  </div>

                  <i onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu();
                  }} className={`fas fa-plus ${css.addIcon}`}></i>


                </div>


              </div>
            </div>
            {node.hasSubTree && (
              <div 
                onMouseEnter={() => setIsSubTreeHovered(true)}
                onMouseLeave={() => setIsSubTreeHovered(false)}
              >
                <div
                  className={classNames(css.sub, css[node.gender])}
                  onClick={clickSubHandler}
                />
                {isSubTreeHovered && (
                  <button 
                    type="button" 
                    className="btn btn-default" 
                    data-toggle="tooltip" 
                    data-html="true" 
                    title="<em>Tooltip</em> <u>with</u> <b>HTML</b>"
                    style={{ minWidth: '10rem' }}
                  >
                    Click to see the family of {node.firstName}
                  </button>
                )}
              </div>
            )}
            {/* place for image popup */}

          </div>
          {isImageOpen && (
            <div className={css.overlay}>
              <div className={css.formContainer}>
                <h2>Upload Profile Picture</h2>
                <form onSubmit={handleSubmitImage}>
                  <div className={css.profileImageContainer}>
                    <img
                      id="profileImage"
                      src={uploadedImage || (node.gender === 'male' ? male : female)}
                      alt="Profile Preview"
                      className={css.profilePic}
                    />
                  </div>
                  <div className={css.formGroup}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                  <div className={css.formActions}>
                    <button type="submit">{isLoading ? "Submitting ..." : "Submit"}</button>
                    <button type="button" onClick={toggleImage}>
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

        </div>
      </section>
    );
  },
);
