import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../../api/api';

interface SuccessState {
    message?: string;
  }

  interface ErrorState {
    message?: string;
  }

const VerifyEmail: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<ErrorState>({});
  const [success, setSuccess] = useState<SuccessState>({});
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const verifyToken = async (token: string) => {
    try {
      setLoading(true);
      setErrors({});
      setSuccess({});
      const res = await API.get(`/auth/verify/${token}`);
      if (res.status === 200) {
        setSuccess({
          message: 'Verification is done',
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error instanceof Error) {
        setErrors({
          message: (error as any).response?.data?.message || 'Failed, try again',
        });
      }
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
    } else {
      setLoading(false);
      setErrors({ message: 'No token found in the query parameters.' });
    }
  }, [token]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
      {loading && (
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      {!loading && errors?.message && (
        <div className="text-center">
          <h2 className="mb-4 text-danger">Error!</h2>
          <p>{errors.message}</p>
          <a href="/login" className="btn btn-primary mt-3">
            Login →
          </a>
        </div>
      )}

      {!loading && success.message && (
        <div className="text-center">
          <h2 className="mb-4 text-success">{success.message}</h2>
          <p className="lead">Your account has been successfully verified! Please click to login.</p>
          <a href="/login" className="btn btn-primary mt-3">
            Login →
          </a>
        </div>
      )}

      {!loading && !errors && !success.message && (
        <p className="h5 text-center">No token found in the query parameters.</p>
      )}
    </div>
  );
};

export default VerifyEmail;