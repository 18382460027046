import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

interface RegisterForm {
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  email: string;
  password: string;
  imageUrl: string;
  dateOfBirth: string;
  city: string;
  confirmPassword: string;
}

const Register: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<RegisterForm>({
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    email: '',
    password: '',
    imageUrl: '',
    dateOfBirth: '',
    city: '',
    confirmPassword: '',
  });

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [inputNames, setInputNames] = useState({
    email: 'email',
    password: 'password',
    confirmPassword: 'confirmPassword'
  });

  useEffect(() => {
    // Generate random names for inputs on component mount
    const randomString = () => Math.random().toString(36).substring(7);
    setInputNames({
      email: `email_${randomString()}`,
      password: `password_${randomString()}`,
      confirmPassword: `confirmPassword_${randomString()}`
    });
  }, []);

  const preventAutofill = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.setAttribute('autocomplete', 'new-password');
    e.target.setAttribute('readonly', 'readonly');
    setTimeout(() => {
      e.target.removeAttribute('readonly');
    }, 100);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    // Map the random name back to the original name
    const originalName = Object.keys(inputNames).find(key => inputNames[key as keyof typeof inputNames] === name) || name;
    setFormData((prev) => ({ ...prev, [originalName]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setMessage("Creating your account...");
    setError(null);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      setMessage("Setting up your profile..."); 
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, formData);
      if (response.data.status === 201) {
      setMessage("🎉 Registration successful! Please check your email to activate your account and start your journey with iGiTREE.");
      } 
    } catch (err: any) {
      setMessage(null);
      if (err.response?.data?.message === 'The email address is already in use by another account.') {
        setError("The email address is already in use by another account.");
        return;
      } else if (err.response?.data?.message === 'Invalid phone number format. Must be in E.164 format.') {
        setError('Please enter your phone number in international format (e.g.+250788123456)');
        return;
      } else if (
        err.response?.data?.message === 'Error while making request: getaddrinfo ENOTFOUND identitytoolkit.googleapis.com. Error code: ENOTFOUND' || 
        err.response?.data?.message === 'Credential implementation provided to initializeApp() via the "credential" property failed to fetch a valid Google OAuth2 access token with the following error: "Error fetching access token: Error while making request: getaddrinfo ENOTFOUND accounts.google.com. Error code: ENOTFOUND"'
      ) {
        setError("Please check your internet connection and try again.");
        return;
      }
      
      setError(err.response?.data?.message || err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 register-background d-none d-sm-block"></div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center">
            <a href="/">
              <img src={logo} className="img-fluid d-block mx-auto mb-4" alt="iGiTREE logo" style={{ width: '15%' }} />
            </a>
          </div>
          <h5 className="text-center text-primary">Sign Up To iGiTREE</h5>
          <form onSubmit={handleSubmit} className="main-input" autoComplete="off">
            <div className="row">
              {/* First Name */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-address-card"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} required />
                    <label>First Name</label>
                  </div>
                </div>
              </div>

              {/* Last Name */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-user-tie"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} required />
                    <label>Last Name</label>
                  </div>
                </div>
              </div>

              {/* Gender */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-venus-mars"></i></span>
                  <div className="form-floating">
                    <select className="form-control" name="gender" value={formData.gender} onChange={handleChange} required>
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <label>Gender</label>
                  </div>
                </div>
              </div>

              {/* Phone Number */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-phone"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                    <label>Phone Number</label>
                  </div>
                </div>
              </div>

              {/* Hidden username field to trick browser */}
              <input type="text" name="username" style={{display: 'none'}} />

              {/* Email */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-envelope"></i></span>
                  <div className="form-floating">
                    <input 
                      type="email" 
                      className="form-control" 
                      placeholder="Email Address" 
                      name={inputNames.email}
                      value={formData.email} 
                      onChange={handleChange} 
                      required 
                      autoComplete="off"
                      onFocus={preventAutofill}
                    />
                    <label>Email Address</label>
                  </div>
                </div>
              </div>

              {/* Password */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-lock"></i></span>
                  <div className="form-floating position-relative">
                    <input 
                      type={showPassword ? "text" : "password"} 
                      className="form-control" 
                      placeholder="Password" 
                      name={inputNames.password}
                      value={formData.password} 
                      onChange={handleChange} 
                      required 
                      autoComplete="new-password"
                      onFocus={preventAutofill}
                      data-lpignore="true"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                    <label>Password</label>
                    <i 
                      className={`fa-sharp fa-light ${showPassword ? 'fa-eye-slash' : 'fa-eye'} position-absolute`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        zIndex: 5
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              {/* Confirm Password */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-lock"></i></span>
                  <div className="form-floating position-relative">
                    <input 
                      type={showConfirmPassword ? "text" : "password"} 
                      className="form-control" 
                      placeholder="Confirm Password" 
                      name={inputNames.confirmPassword}
                      value={formData.confirmPassword} 
                      onChange={handleChange} 
                      required 
                      autoComplete="new-password"
                      onFocus={preventAutofill}
                      data-lpignore="true"
                      autoCapitalize="off"
                      spellCheck="false"
                    />
                    <label>Confirm Password</label>
                    <i 
                      className={`fa-sharp fa-light ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} position-absolute`}
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      style={{
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        zIndex: 5
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              {/* Date of Birth */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-calendar"></i></span>
                  <div className="form-floating">
                    <input type="date" className="form-control" placeholder="Date of Birth" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required />
                    <label>Date of Birth</label>
                  </div>
                </div>
              </div>

              {/* City */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-city"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="City" name="city" value={formData.city} onChange={handleChange} required />
                    <label>City</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mt-2">
                <div className="d-grid gap-2">
                  <button className="btn btn-primary btn-lg btn-flat" type="submit">{loading ? "Loading ..." : "Sign Up"}</button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                {message && <div className="alert alert-success">{message}</div>}
                {error && <div className="bg-danger color-palette p-2">{error}</div>}
              </div>
            </div>
          </form>
          
          {/* Terms and Existing Account Links */}
          <div className="row mt-3">
            <div className="col-md-12 text-center g-font-size-10--xs">
              By clicking the "Sign up" button, you are creating an account and agree to the <a href="/terms-of-use">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              Already have an account? <a href="/login" className="text-strong">Log In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
