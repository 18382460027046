import axios from "axios";
import { auth } from "../firebase.config";

// Create an Axios instance
const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

// Set up an interceptor to catch 401 responses
API.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        // Get the current user
        const user = auth.currentUser;

        if (user) {
          // Refresh the token
          const newToken = await user.getIdToken(true);

          // Update the Authorization header and retry the request
          error.config.headers["Authorization"] = `Bearer ${newToken}`;
          return API.request(error.config);
        } else {
          // If no user is signed in, navigate to login or handle accordingly
          window.location.href = "/login";
        }
      } catch (tokenError) {
        console.error("Error refreshing token: ", tokenError);
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default API;
