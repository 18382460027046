import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowUp } from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Quick Links</h5>
            <div className="row g-2 pt-2">
              <Link className="btn btn-link text-white-50" to="/">Home</Link>
              <Link className="btn btn-link text-white-50" to="/about">About</Link>
              <Link className="btn btn-link text-white-50" to="/services">Services</Link>
              <Link className="btn btn-link text-white-50" to="/contact-us">Contact Us</Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Blog</h5>
            <Link className="btn btn-link text-white-50" to="/user-stories">User Stories</Link>
            <Link className="btn btn-link text-white-50" to="/become-an-affiliate">Become An Affiliate</Link>
          </div>

          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>1 KN 78 St, Kigali</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+250 787 961 983</p>
            <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@igitree.rw</p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="https://twitter.com"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://facebook.com"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://instagram.com"><i className="fab fa-instagram"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://linkedin.com"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Newsletter</h5>
            <p>Get the latest news straight to your email by subscribing to our network</p>
            <form >
              <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}>
                <input 
                  className="form-control bg-transparent w-100 py-3 ps-4 pe-5 text-white" 
                  type="email" 
                  name="email" 
                  placeholder="Your email" 
                  required 
                />
                <button 
                  type="submit" 
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2" 
                  name="doInsert" 
                  value="Submit"
                  style={{ width: 'auto' }}
                >
                  <i className="fa-sharp fa-light fa-paper-plane-top"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-4 text-center text-md-start mb-3 mb-md-0">
              &copy; <Link to="#">iGiTREE</Link>, All Rights Reserved.
            </div>
            <div className="col-md-8 text-center text-md-end">
              <div className="footer-menu">
                <Link to="/">Home</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms And Conditions</Link>
                <Link to="/cookies-policy">Cookies Policy</Link>
                <Link to="/support">Support</Link>
                <Link to="/faq">FAQs</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><FaArrowUp /></a>
    </div>
  );
};

export default Footer;