import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import type { Node, ExtNode } from 'relatives-tree/lib/types';
import treePackage from 'relatives-tree/package.json';
import ReactFamilyTree from 'react-family-tree';
import { PinchZoomPan } from '../PinchZoomPan/PinchZoomPan';
import { FamilyNode } from '../FamilyNode/FamilyNode';
import { NodeDetails } from '../NodeDetails/NodeDetails';
import { NODE_WIDTH, NODE_HEIGHT } from '../const';
import { getNodeStyle } from './utils';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import css from './App.module.css';
import { Link, useParams } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { EditFamilyNodeContext } from '../../context/EditFamilyNodeContext';
import API from '../../api/api';

interface CustomNode extends ExtNode {
  firstName: string;
  lastName: string;
  imageUrl: string;
  age: string;
}
// NewMemberForm.tsx
interface NewMemberFormProps {
  onClose: () => void;
  option: string;
  node: CustomNode | null; // Allow null for node
}

interface CustomNode extends Node {
  firstName: string;
  lastName: string;
  imageUrl: string;
  dateOfBirth: string;
}

const generatePDF = async () => {
  // Elements to hide
  const backButton = document.querySelector(`.${css.backButton}`);
  const downloadButton = document.querySelector(`.${css.backButton2}`);

  // Add a class to hide elements
  backButton?.classList.add(css.hidden);
  downloadButton?.classList.add(css.hidden);

  try {
    const element = document.getElementById("family-tree"); // Ensure this ID is set on the family tree container
    if (element) {
      const canvas = await html2canvas(element, {
        backgroundColor: null, // Captures the current background (including transparency)
        useCORS: true, // Ensures cross-origin resources are loaded if applicable
      });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // Adjust width as needed
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("family-tree.pdf");
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    // Remove the class to show the elements again
    backButton?.classList.remove(css.hidden);
    downloadButton?.classList.remove(css.hidden);
  }
};


export default React.memo(function App() {
  const context = useContext(EditFamilyNodeContext);
  const { board_id } = useParams<{ board_id: string }>();
  const [nodes, setNodes] = useState<readonly Readonly<CustomNode>[]>([]);
  const [rootId, setRootId] = useState<string>('');
  const [selectId, setSelectId] = useState<string>();
  const [hoverId, setHoverId] = useState<string>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [familyData, setFamilyData] = useState<CustomNode[]>([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false); // New state for delete dialog
  const [nodeToDelete, setNodeToDelete] = useState<CustomNode | null>(null); // Store the node to delete
  const [loading, setLoading] = useState<boolean>(false);
  const [currentService, setCurrentservice] = useState<string>("");
  const [formProps, setFormProps] = useState<{ option: string; node: CustomNode | null }>({
    option: "",
    node: null,
  });

  if (!context) {
    throw new Error('EditFamilyNodeContext must be used within an EditFamilyNodeProvider');
  }

  const { openEditor } = context;
  const openForm = (option: any, node: any) => {
    setFormProps({ option, node });
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };
  const [isFormOpen, setFormOpen] = useState(true);

  const { user } = useUser();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    imageUrl: '',
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    city: '',
    gender: '',
    parents: [],
    siblings: [],
    spouses: [],
    children: [],
  });

  const fetchFamilyMembers = useCallback(async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("token") || '""');
      const response = await API.get(`/family_tree/${board_id}/member`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data.members)) {
        const customNodes = response.data.members.map((node: any) => ({
          ...node,
          firstName: node.firstName || 'Unknown',
          lastName: node.lastName || 'Unknown',
        }));
        setNodes(customNodes);
        setRootId(customNodes[0]?.id);
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoading(false);
    }
  }, [board_id]);

  useEffect(() => {
    if (user) {
      fetchFamilyMembers();
    }
  }, [user, fetchFamilyMembers]);

  const resetRootHandler = useCallback(() => setRootId(nodes[0]?.id || ''), [nodes]);

  const selected = useMemo(
    () => nodes.find((item) => item.id === selectId),
    [nodes, selectId]
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("token") || '""');
      await API.post(`/family_tree/${board_id}/member`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      fetchFamilyMembers();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error);
    }
    setShowForm(false);
  };
  const handleMemberDelete = async () => {
    // if (!nodeToDelete) return;
    setDeleteDialogOpen(false);
    setLoading(true);
    try {
      const token =await JSON.parse(localStorage.getItem("token") || '""');
      await API.delete(`/family_tree/${board_id}/clear`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      fetchFamilyMembers(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting members:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchFamilyTreeData = async () => {
    await fetchFamilyMembers();
  }

  const confirmDeleteAction = (e: React.MouseEvent) => {
    // setNodeToDelete(nodes.find(node => node.id === nodeId) || null); // Set the node to delete
    setDeleteDialogOpen(true); // Open the delete dialog
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false); // Close the delete dialog
  };
  return (
    <div className={css.root} id="family-tree">
      {loading ? <div className={css.loader}>Loading...</div> : nodes.length === 0 ? (
        <div className={css.emptyMessage}>
          <div className={css.emptyMessageContent}>
            <p>
              It looks like there's no one in the family tree yet. You can start by adding yourself to the family tree!
            </p>
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '10px',
              alignItems: 'center'
            }}>
              <button 
                className="btn btn-outline-primary mb-2" 
                onClick={() => setShowForm(true)}
                style={{ width: 'auto', textAlign: 'center' }}
              >
                Add yourself
              </button>
              <Link 
                to="/dashboard" 
                className="btn btn-outline-primary"
                style={{ width: '4rem', textAlign: 'center' }}
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Link to="/dashboard" className={`btn btn-outline-primary mt-2 ml-2 ${css.backButton}`}>Back</Link>
          <button className={`btn btn-outline-primary mt-2 ml-2 ${css.backButton}`} onClick={confirmDeleteAction}>
            Clear
          </button>
          <button onClick={generatePDF} className={`btn btn-outline-primary mt-2 ${css.backButton2}`}>
  Download Family Tree as PDF
</button>
          <PinchZoomPan min={0.01} max={30} captureWheel className={css.wrapper}>
            <ReactFamilyTree
              nodes={nodes}
              rootId={rootId}
              width={NODE_WIDTH}
              height={NODE_HEIGHT}
              className={css.tree}
              renderNode={(node: ExtNode) => (
                <FamilyNode
                  key={node.id}
                  node={node as CustomNode}
                  isRoot={node.id === rootId}
                  isHover={node.id === hoverId}
                  onClick={setSelectId}
                  onSubClick={setRootId}
                  style={getNodeStyle(node)}
                  onMemberDelete={handleMemberDelete}
                  onFamilyTreeUpdate={fetchFamilyTreeData}
                />
              )}
            />
          </PinchZoomPan>

          {rootId !== nodes[0]?.id && (
            <button className={css.reset} onClick={resetRootHandler}>
              Reset
            </button>
          )}

          {selected && (
            <NodeDetails
              node={selected}
              className={css.details}
              onSelect={setSelectId}
              onHover={setHoverId}
              onClear={() => setHoverId(undefined)}
            />
          )}
        </>
      )}
      {showForm && (
        <div className={css.popup}>
          <form onSubmit={handleFormSubmit}>
            <h2>Add Yourself </h2>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="date"
              name="dateOfBirth"
              placeholder="Date of Birth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
            />
            <select
              name="gender"
              value={formData.gender}
              required
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="male" >Male</option>
              <option value="female">Female</option>
            </select>
            <button type="submit">{loading ? "Loading ..." : "Submit"}</button>
            <button type="button" onClick={() => setShowForm(false)}>
              Cancel
            </button>
          </form>
        </div>
      )}

{isDeleteDialogOpen && (
        <div className={css.deleteDialog}>
          <div className={css.deleteDialogContent}>
            <h4>Are you sure you want to delete all family members?</h4>
            <button onClick={(e) => handleMemberDelete()} className={css.deleteButton}>
              Yes, Delete
            </button>
            <button onClick={cancelDelete} className={css.cancelButton}>
              Cancel
            </button>
          </div>
        </div>
      )}

    </div>
  );
});
