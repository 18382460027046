import React, { useState } from 'react';
import NavBar from '../Welcome/NavBar';
import Footer from '../Welcome/Footer';
import profileImage from '../../assets/img/profile/default.png'; 
import { BsPersonFillAdd } from 'react-icons/bs'; 
import DashboardMain from './DashboardMain';
import Notifications from './Notifications';
import { auth } from '../../firebase.config';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import male from "../FamilyNode/male-avatar.png";
import female from "../FamilyNode/female-avatar.png";
import css from "../FamilyNode/FamilyNode.module.css";
import uploadImage from '../../helper/uploadImage';
import API from '../../api/api';
import { Error, Success } from '../Form';

// Define prop types (e.g., for dynamic content)
interface LandingPageProps {
  page: string;
  keywords: string;
  description: string;
  twitter: string;
  services: {
    serviceId: string;
    serviceName: string;
    serviceImage: string;
    serviceDescription: string;
    serviceCode: string;
  }[];
  notifications: {
    notification_id: string;
    notification_date: string;
    user_id: string;
    notification_main: string;
    notification_type: string;
    notification_read: string;
    notification_code: string;
  }[];
}

interface Messages {
  message?: string
}
const notifications = [
  {
      notification_id: '1',
      notification_date: '2024-10-29T10:00:00Z',
      user_id: '123',
      notification_main: 'New friend request!',
      notification_type: 'friend_request',
      notification_read: 'false',
      notification_code: 'FRIEND_REQ',
  },
];

const Dashboard: React.FC<LandingPageProps> = ({ page, keywords, description, twitter }) => {
  const { user, setUser, loading } = useUser();
  const fullname = user?.firstName +" "+ user?.lastName;
  const email = user?.email;
  const phone = user?.phoneNumber  ;
  
  // State to manage the active tab
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isImageOpen, setImageOpen] = useState(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [errors, setError] = useState<Messages>({});
  const [success, setSuccess] = useState<Messages>({});
  const [membersCount, setMembersCount] = useState<number>(0); 
  const toggleImage = () => {
    setImageOpen(!isImageOpen);
  }
const navigate = useNavigate();
  const handleSignOut = async (e: any) => {
    e.preventDefault();
    try {
      auth.signOut();
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      setUser(null);
      navigate("/login");
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  const handleProfileChange =(e: any)=>{
    e.preventDefault();
    toggleImage();
    console.log("you can upload now");
  }

  const handleSubmitImage =async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    if (!uploadedImageFile) {
      console.error("No image file uploaded");
      return;
    }
  const genelatedImage =await  uploadImage(uploadedImageFile, "simple");
  console.log(genelatedImage);
  try {
    setError({});
    setSuccess({});

    const token = JSON.parse(localStorage.getItem("token") || '""');
    const response = await API.put(
      '/auth/profleimage',
      { imageUrl: genelatedImage },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      setSuccess({
        message: "Profile picture updated successfully.",
      });
    } else {
      setError({
        message:"Failed to update profile picture.",
      });
    }
    setIsLoading(true);
    localStorage.setItem("user", JSON.stringify({...user,imageUrl: genelatedImage}));
    toggleImage();
    window.location.reload();

  } catch (error: any) {
    setError({  
      message: error.response?.data?.message || 'Please try again',
    });
    console.error("Error updating profile picture:", error);
  } finally {
    setIsLoading(false);
  }


  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMembersCountUpdate = (newCount: number) => {
    console.log("Dashboard received new count:", newCount); // Debug log
    setMembersCount(newCount);
  };
  return (
    <div>
      <NavBar />

      <div className="container-xxl bg-white p-0">
        {/* Spinner */}
        <div id="spinner" className="spinner-container">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>

        {/* About Section */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-4">

              <div className="col-md-3 col-sm-12">
                {/* Profile Card */}
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <a href="#">
                      <img src={user?.imageUrl || profileImage} className="img-fluid" alt="Profile" />
                    </a>

                    <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      <a className="btn btn-square mx-1" href="/"  onClick={handleProfileChange}>
                        <BsPersonFillAdd className="text-primary" /> {/* Use the imported icon */}
                      </a>
                    </div>
                  </div>
                  <div className="text-center p-4 mt-3">
                    <h5 className="fw-bold mb-0">{fullname}</h5>
                    <small>{email}</small><br />
                    <small>{phone}</small>
                  </div>
                </div>

                {/* Friends, Memories, Events */}
                <div className="row mt-4">
                  <div className="col-md-12 col-sm-12">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <strong><i className="fa-sharp fa-light fa-users mr-2"></i>Members</strong>
                        <span className="badge badge-sm text-bg-secondary float-end">{membersCount}</span>
                      </li>
                      {/* <li className="list-group-item">
                        <strong><i className="fa-sharp fa-light fa-users"></i> Memories</strong>
                        <span className="badge badge-sm text-bg-secondary float-end">2</span>
                      </li>
                      <li className="list-group-item">
                        <strong><i className="fa-sharp fa-light fa-calendar-star"></i> Events</strong>
                        <span className="badge badge-sm text-bg-secondary float-end">2</span>
                      </li> */}
                    </ul>
                  </div>
                </div>

                {/* Logout Button */}
                <div className="row mt-4">
                  <div className="col-md-12 col-sm-12">
                    <div className="d-grid gap-2">
                      <a className="btn btn-danger btn-flat" type="button" onClick={handleSignOut}>
                        <i className="fa-sharp fa-light fa-arrow-right-from-bracket mr-2"></i> Logout
                      </a>
                    </div>
                  </div>
                </div>

                
                <div className="row mt-3">
  {/* <div className="col-md-12 col-sm-12">
    <div className="d-grid gap-2">
      <button className="btn btn-outline-danger btn-flat" type="button" >
        <i className="fa-sharp fa-light fa-user-xmark mr-2"></i> Delete Account
      </button>
    </div>
  </div> */}
</div>

              </div>

              {/* Main Dashboard Content */}
              <div className="col-md-9 col-sm-12">
                <div className="team-item p-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <ul className="nav nav-underline nav-fill g-font-size-18--xs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${activeTab === 'dashboard' ? 'active' : ''}`}
                            onClick={() => setActiveTab('dashboard')}
                            type="button"
                            role="tab"
                            aria-controls="dashboard"
                            aria-selected={activeTab === 'dashboard'}
                          >
                            <i className="fa-sharp fa-light fa-gauge"></i>
                            Dashboard
                          </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`}
                            onClick={() => setActiveTab('notifications')}
                            type="button"
                            role="tab"
                            aria-controls="notifications"
                            aria-selected={activeTab === 'notifications'}
                          >
                            <i className="fa-sharp fa-light fa-bell"></i> 
                            Notifications
                            <span className="badge badge-sm text-bg-secondary">0</span>
                          </button>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${activeTab === 'messages' ? 'active' : ''}`}
                            onClick={() => setActiveTab('messages')}
                            type="button"
                            role="tab"
                            aria-controls="messages"
                            aria-selected={activeTab === 'messages'}
                          >
                            <i className="fa-sharp fa-light fa-messages"></i> Messages
                          </button>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`}
                            onClick={() => setActiveTab('settings')}
                            type="button"
                            role="tab"
                            aria-controls="settings"
                            aria-selected={activeTab === 'settings'}
                          >
                            <i className="fa-sharp fa-light fa-gears"></i> Profile
                          </button>
                        </li> */}
                      </ul>

                      <div className="tab-content mt-4" id="myTabContent">
                        {activeTab === 'dashboard' && (
                          <div className="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                          <DashboardMain 

      onMembersCountUpdate={handleMembersCountUpdate}
    />
                        </div>
                        )}
                        {activeTab === 'notifications' && (
                    <div className="tab-pane fade" id="notifications" role="tabpanel" aria-labelledby="notifications-tab">
                            <Notifications notifications={notifications} /> {/* Use the Notifications component here */}
                          </div>
                        )}
                        {activeTab === 'messages' && (
                          <div className="tab-pane fade" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            {/* Replace with your messages component */}
                            <h5>Messages Content Here</h5>
                          </div>
                        )}
                        {activeTab === 'settings' && (
                          <div className="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            {/* Replace with your settings/profile component */}
                            <h5>Profile Content Here</h5>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />
      {isImageOpen && (
          <div className={css.overlay}>
            <div className={css.formContainer}>
              <h2>Upload Profile Picture</h2>
              <form onSubmit={handleSubmitImage}>
                <div className={css.profileImageContainer}>
                  <img
                    id="profileImage"
                    src={uploadedImage || male}
                    alt="Profile Preview"
                    className={css.profilePic}
                  />
                </div>
                <div className={css.formGroup}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
                <div className={css.formActions}>
                  <button type="submit">{isLoading ? "Loading ..." : "Submit"}</button>
                  <button type="button" onClick={toggleImage}>
                    Close
                  </button>
                </div>
              </form>
              {errors?.message && <Error>{errors.message}</Error>}
              {success?.message && <Success>{success.message}</Success>}
            </div>
          </div>
        )}
    </div>
  );
};

export default Dashboard;
